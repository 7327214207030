import React from 'react'
import Navbar from './Navbar';
import Footer from './Footer';

const Team = () => {
  return (
    <>
    <Navbar/>
    <a 
    href="https://api.whatsapp.com/send?phone=7081056230&text=Hello%20nice%20to%20meet%20you" 
    class="float" 
    target="_blank">
    <small className='me-2'></small>
    <i class="bi bi-whatsapp my-float"></i>
</a>
    <div className="container-fluid page-header py-5 mb-5">
        <div className="container py-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">ESS Team</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
                    <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                    <li className="breadcrumb-item text-white active" aria-current="page">Our Team</li>
                </ol>
            </nav>
        </div>
    </div>

{/* Our Team  */}
<div className="container-xxl py-5">
        <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <div className="bg-primary mb-3 mx-auto diff-height"></div>
                <h1 className="display-5 mb-5">Team Members</h1>
            </div>
            <div className="row g-4">
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="team-item">
                        <div className="overflow-hidden position-relative">
                            <img className="img-fluid" src="images/team-2.jpg" alt=""/>
                            <div className="team-social">
                         <small>   we provide positive feedback to our clients and maintain high quality standards and Defining and
                            executing the company’s long-term vision and goals </small>
                            </div>
                        </div>
                        <div className="text-center p-4">
                            <h5 className="mb-0">Mr. Dashrath prajapati</h5>
                            <span className="text-primary">Managing director</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="team-item">
                        <div className="overflow-hidden position-relative">
                            <img className="img-fluid" src="images/akash.png" alt=""/>
                            <div className="team-social">
                         <small>   As the frontline of our company, driving revenue and building lasting relationships with customers.
                         This role is centered on generating leads, closing sales, and maintaining strong customer relationships. </small>
                            </div>
                        </div>
                        <div className="text-center p-4">
                            <h5 className="mb-0">Mr. Akshay kumar</h5>
                            <span className="text-primary">Operation manager</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="team-item">
                        <div className="overflow-hidden position-relative">
                        <img className="img-fluid" src="images/t.jpg" alt=""/>
                            <div className="team-social">
                         <small>   As the frontline of our company, driving revenue and building lasting relationships with customers.
                         This role is centered on generating leads, closing sales, and maintaining strong customer relationships. </small>
                            </div>
                        </div>
                        <div className="text-center p-4">
                            <h5 className="mb-0">Mr. Krishnanand Maury</h5>
                            <span className="text-primary">Sales Executive</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="team-item">
                        <div className="overflow-hidden position-relative">
                            <img className="img-fluid" src="images/shubham.jpg" alt=""/>
                            <div className="team-social">
                         <small>   Excelnet security on duty, you can focus on conducting exams with confidence, knowing that the
                            integrity of your assessments is being safeguarded </small>
                            </div>
                        </div>
                        <div className="text-center p-4">
                            <h5 className="mb-0">Mr. Shubham Patel</h5>
                            <span className="text-primary">Product & CPO</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid about-maincontainer pt-4 pb-5">
        <div className="container">
            <div className="row about-usrow">
                <div className="col-lg-5">
                    <div className="about-page">
                        <img src="images/team-img.jpg" alt=""/>
                        <div className="small-extrabox">
                            <p>We want to build a world where great ideas,
                                products, and companies come to fruition
                                quickly whilst achieving the required impact.
                                Our work is to facilitate the process.</p>
                            <h6>Excelnet Security</h6>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="about-pagetext">
                        <h6>OUR TEAM AT EXCELNET SECURITY</h6>
                        <h4>WE PROVIDE SMART SECURITY SOLUTION FOR ALL BUSINESS</h4>
                            <p>In the digital age, protecting your content is essential for maintaining trust and integrity. At Excelnet
                            Security system, we specialize in delivering cutting-edge security solutions designed to safeguard your
                            sensitive information against evolving cyber threats. Our mission is to provide businesses like yours
                            with peace of mind through comprehensive content protection strategies </p>
                            <p>In the digital age, protecting your content is essential for maintaining trust and integrity. At Excelnet
                                Security system, we specialize in delivering cutting-edge security solutions designed to safeguard your
                                sensitive information against evolving cyber threats. Our mission is to provide businesses like yours
                                with peace of mind through comprehensive content protection strategies. In the digital age, protecting your content is essential for maintaining trust and integrity. At Excelnet
                            Security system, we specialize in delivering cutting-edge security solutions designed to safeguard your
                            sensitive information against evolving cyber threats. Our mission is to provide businesses like yours
                            with peace of mind through comprehensive content protection strategies </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Footer/>
    </>
  )
}

export default Team