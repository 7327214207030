import React from 'react'
import Navbar from './Navbar';
import Footer from './Footer';

const Service = () => {
  return (
  <>
  <Navbar/>
  <a 
    href="https://api.whatsapp.com/send?phone=7081056230&text=Hello%20nice%20to%20meet%20you" 
    class="float" 
    target="_blank">
    <small className='me-2'></small>
    <i class="bi bi-whatsapp my-float"></i>
</a>
  <div class="container-fluid page-header py-5 mb-5">
        <div class="container py-5">
            <h1 class="display-3 text-white mb-3 animated slideInDown">Services</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                    <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                    <li class="breadcrumb-item text-white active" aria-current="page">Services</li>
                </ol>
            </nav>
        </div>
    </div>

    {/* Our Services  */}

    <div class="container-xxl py-5">
        <div class="container">
            <div class="text-center">
                <div class="bg-primary mb-3 mx-auto diff-height"></div>
                <h1 class="div-heading display-5 mb-5">ESS Services</h1>
            </div>
            <div class="row g-0 service-row">
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                    <div class="service-item border h-100 p-4">
                        <div class="btn-square bg-light rounded-circle mb-4 same-height">
                            <img class="img-fluid" src="images/icon-9.png" alt="Icon"/>
                        </div>
                        <h4 class="mb-3">Security guard</h4>
                        <p class="mb-4">The mere presence of security guards can deter criminal activity such as theft and trespassing.</p>
                        <a class="btn" href=""><i class="fa fa-arrow-right text-white me-3"></i>Read More</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                    <div class="service-item border h-100 p-4">
                        <div class="btn-square bg-light rounded-circle mb-4 same-height">
                            <img class="img-fluid" src="images/icon-6.png" alt="Icon"/>
                        </div>
                        <h4 class="mb-3">CCTV System</h4>
                        <p class="mb-4">A CCTV System offers 24/7 high-definition surveillance to protect and monitor your premises.</p>
                        <a class="btn" href=""><i class="fa fa-arrow-right text-white me-3"></i>Read More</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                    <div class="service-item border h-100 p-4">
                        <div class="btn-square bg-light rounded-circle mb-4 same-height">
                            <img class="img-fluid" src="images/icon-3.png" alt="Icon"/>
                        </div>
                        <h4 class="mb-3">Smart Home Security</h4>
                        <p class="mb-4">Smart Home Security offers remote monitoring and automated protection for your home.</p>
                        <a class="btn" href=""><i class="fa fa-arrow-right text-white me-3"></i>Read More</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
                    <div class="service-item border h-100 p-4">
                        <div class="btn-square bg-light rounded-circle mb-4 same-height">
                            <img class="img-fluid" src="images/icon-8.png" alt="Icon"/>
                        </div>
                        <h4 class="mb-3">Finger Print Access</h4>
                        <p class="mb-4">ingerprint Access provides secure, convenient, and fast entry with biometric authentication.</p>
                        <a class="btn" href=""><i class="fa fa-arrow-right text-white me-3"></i>Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className="container">
    <div className="row align-items-center">
            <div className="col-lg-5">
                <div className="service-img-box">
                    <img src="images/home.png" alt="" />
                </div>
            </div>
            <div className="col-lg-7">
                <div className="service-text-box">
                    <h4>At Home</h4>
                    <p className='pt-4'>Your home is your sanctuary, a place where you and your loved ones should feel safe and secure.
                        However, residential properties are not immune to security risks such as burglary, vandalism, or unauthorized
                        access. Here's why you should consider approaching security guards for your home</p>
                        <button className='mt-4'>Learn More</button>
                </div>
            </div>
        </div>
        <div className="row align-items-center">
        <div className="col-lg-7">
                <div className="service-text-box">
                    <h4>Security Guard Services</h4>
                    <p className='pt-4'>Our highly trained security guards are the frontline defense for your property and assets.
                        With rigorous training and expertise in risk assessment, conflict resolution, and emergency response, our
                        security personnel are equipped to handle any situation with professionalism and efficiency.</p>
                        <button className='mt-4'>Learn More</button>
                </div>
            </div>
            <div className="col-lg-5">
                <div className="service-img-box">
                    <img src="images/new.jpg" alt="" />
                </div>
            </div>
        </div>
        <div className="row align-items-center">
        <div className="col-lg-5">
                <div className="service-img-box">
                    <img src="images/home.png" alt="" />
                </div>
            </div>
            <div className="col-lg-7">
                <div className="service-text-box">
                    <h4>At Commercial Places
                    </h4>
                    <p className='pt-4'>In the commercial sector, security guards play a crucial role in protecting businesses,
                        employees, customers, and assets. Whether it's a retail store, office building, or industrial facility, here's
                        why approaching security guards is essential for commercial places</p>
                        <button className='mt-4'>Learn More</button>
                </div>
            </div>
        </div>
        <div className="row align-items-center">
            <div className="col-lg-7">
                <div className="service-text-box">
                    <h4>Patrol Services</h4>
                    <p className='pt-4'>Our mobile patrol units offer an added layer of security, conducting regular patrols of your
                        premises to deter trespassing, vandalism, and other criminal activities. With GPS tracking and real-time
                        reporting, you can trust that your property is under constant surveillance</p>
                        <button className='mt-4'>Learn More</button>
                </div>
            </div>
            <div className="col-lg-5">
                <div className="service-img-box">
                    <img src="images/security.jpg" alt="" />
                </div>
            </div>
        </div>
        <div className="row align-items-center">
            <div className="col-lg-5">
                <div className="service-img-box">
                    <img src="images/new15.jpg" alt="" />
                </div>
            </div>
            <div className="col-lg-7">
                <div className="service-text-box">
                    <h4>Alarm Monitoring</h4>
                    <p className='pt-4'>We provide state-of-the-art alarm monitoring services to ensure rapid response
                        in the event of an emergency. Our monitoring center operates 24/7, monitoring fire alarms, intrusion alarms,
                        and surveillance systems, so you can rest easy knowing that help is always just a call away.</p>
                        <button className='mt-4'>Learn More</button>
                </div>
            </div>
        </div>
        <div className="row align-items-center">
            <div className="col-lg-7">
                <div className="service-text-box">
                    <h4>Event Security</h4>
                    <p className='pt-4'>Hosting a special event? Excelnet security offers expert event security services to ensure the
                        safety and well-being of your guests. From corporate gatherings to private parties, our experienced security
                        personnel will handle crowd control, access management, and emergency response, allowing you to focus on
                        hosting a successful event.</p>
                        <button className='mt-4'>Learn More</button>
                </div>
            </div>
            <div className="col-lg-5">
                <div className="service-img-box">
                    <img src="images/new11.jpg" alt="" />
                </div>
            </div>
        </div>
        <div className="row align-items-center">
            <div className="col-lg-5">
                <div className="service-img-box">
                    <img src="images/new5.jpg" alt="" />
                </div>
            </div>
            <div className="col-lg-7">
                <div className="service-text-box">
                    <h4>Consulting and Risk Assessment</h4>
                    <p className='pt-4'>Our team of security experts will work closely with you to assess your security needs and develop
                        a customized security plan tailored to your specific requirements. Whether you're looking to enhance the security
                        of your business, property, or personal assets, we'll provide strategic guidance and solutions to mitigate risks
                        and vulnerabilities.</p>
                        <button className='mt-4'>Learn More</button>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
  </>
  )
}

export default Service