import React from 'react'
import Navbar from './Navbar';
import Footer from './Footer';
const About = () => {
  return (
    <>
    <Navbar/>
    <a 
    href="https://api.whatsapp.com/send?phone=7081056230&text=Hello%20nice%20to%20meet%20you" 
    class="float" 
    target="_blank">
    <small className='me-2'></small>
    <i class="bi bi-whatsapp my-float"></i>
</a>
    <div class="container-fluid page-header py-5 mb-5">
        <div class="container py-5">
            <h1 class="display-3 text-white mb-3 animated slideInDown">About Us</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                    <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                    <li class="breadcrumb-item text-white active" aria-current="page">About</li>
                </ol>
            </nav>
        </div>
    </div>

    <section className="section-services">
		<div className="container">
			<div className="row">
		
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="content">
							<span className="icon">
                            <i class="bi bi-house-gear-fill"></i>
							</span>
							<h3 className="title">Home Security</h3>
							<p className="description">Security guards help protect valuable assets and intellectual property from theft, or unauthorized access, safeguarding the financial interests.</p>
							<a href="#" className="learn-more">Learn More</a>
						</div>
						<span className="circle-before"></span>
					</div>
				</div>
	
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="content">
							<span className="icon">
							<i class="bi bi-universal-access-circle"></i>
							</span>
							<h3 className="title">Access Control</h3>
							<p className="description">Security guards help deter criminal activity such as theft, shoplifting, or trespassing, minimizing the risk of losses and damage to property</p>
							<a href="#" className="learn-more">Learn More</a>
						</div>
						<span className="circle-before"></span>
					</div>
				</div>
			
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="content">
							<span className="icon">
                            <i class="bi bi-headset"></i>
							</span>
							<h3 className="title">24/7 Support</h3>
							<p className="description">Security guard services can provide round-the-clock security coverage, ensuring that your property is protected at all times.</p>
							<a href="#" className="learn-more">Learn More</a>
						</div>
						<span className="circle-before"></span>
					</div>
				</div>
	
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="content">
							<span className="icon">
							<i class="bi bi-fingerprint"></i>
							</span>
							<h3 className="title">Enhanced Sense of Security</h3>
							<p className="description">Having security guards on-site provides a sense of security and peace of mind to employees, customers, visitors, and residents. </p>
							<a href="#" className="learn-more">Learn More</a>
						</div>
						<span className="circle-before"></span>
					</div>
				</div>
		
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="content">
							<span className="icon">
                            <i class="bi bi-file-earmark-lock2"></i>
							</span>
							<h3 className="title">Security Solutions</h3>
							<p className="description">Security guard services can be tailored to meet the specific needs and requirements of your business or property.</p>
							<a href="#" className="learn-more">Learn More</a>
						</div>
						<span className="circle-before"></span>
					</div>
				</div>
		
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="content">
							<span className="icon">
							<i class="bi bi-shield-plus"></i>
							</span>
							<h3 className="title">Risk Prevention</h3>
							<p className="description">Security guards are trained to assess potential security risks and vulnerabilities and implement preventive measures to mitigate them. </p>
							<a href="#" className="learn-more">Learn More</a>
						</div>
						<span className="circle-before"></span>
					</div>
				</div>
				
			</div>
		</div>
	</section>

 {/* About Start  */}
    <div class="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div class="container about px-lg-0">
            <div class="row g-0 mx-lg-0">
                <div class="col-lg-6 ps-lg-0 about-height">
                    <div class="position-relative h-100">
                        <img class="position-absolute img-fluid w-100 h-100 about-img" src="images/about.jpg" alt=""/>
                    </div>
                </div>
                <div class="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
                    <div class="p-lg-5 pe-lg-0">
                        <div class="bg-primary mb-3 diff-height"></div>
                        <h1 class="div-heading display-5 mb-4">About ESS</h1>
                        <p class="mb-4 pb-2">In the digital age, protecting your content is essential for maintaining trust and integrity.
                            At Excelnet Security system, we specialize in delivering cutting-edge security solutions designed to
                            safeguard your sensitive information against evolving cyber threats. Our mission is to provide businesses
                            like yours with peace of mind through comprehensive content protection strategies</p>
                        <div class="row g-4 mb-4 pb-3">
                            <div class="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                                <div class="d-flex align-items-center">
                                    <div class="btn-square bg-white rounded-circle same-height">
                                        <img class="img-fluid" src="images/icon-1.png" alt="Icon"/>
                                    </div>
                                    <div class="ms-4">
                                        <h2 class="mb-1" data-toggle="counter-up">1034</h2>
                                        <p class="fw-medium text-primary mb-0">Happy Clients</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                                <div class="d-flex align-items-center">
                                    <div class="btn-square bg-white rounded-circle same-height">
                                        <img class="img-fluid" src="images/icon-5.png" alt="Icon"/>
                                    </div>
                                    <div class="ms-4">
                                        <h2 class="mb-1" data-toggle="counter-up">921</h2>
                                        <p class="fw-medium text-primary mb-0">Projects Done</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="" class="btn btn-primary rounded-pill py-3 px-5">Explore More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-5">
                <div className="service-img-box">
                    <img src="images/vision.jpg" alt="" />
                </div>
            </div>
            <div className="col-lg-7">
                <div className="service-text-box">
                    <h4>Our Vision</h4>
                    <p className='pt-4'>At Excelnet security system we are committed to providing unparalleled security solutions
                        tailored to the unique needs of our clients. Our vision and mission guide us in our pursuit of excellence and
                        our dedication to ensuring the safety and security of individuals, businesses, and communities.</p>
                        <button className='mt-4'>Learn More</button>
                </div>
            </div>
        </div>
        <div className="row align-items-center">
            <div className="col-lg-7">
                <div className="service-text-box">
                    <h4>Our Mission</h4>
                    <p className='pt-4'>To be the leading provider of innovative and comprehensive security solutions, setting the
                        standard for excellence in the security industry. We aspire to be the trusted partner that clients turn to
                        for their security needs, delivering peace of mind and protection in an ever-evolving world.</p>
                        <button className='mt-4'>Learn More</button>
                </div>
            </div>
            <div className="col-lg-5">
                <div className="service-img-box">
                    <img src="images/mission.jpg" alt="" />
                </div>
            </div>
        </div>

    </div>
    <Footer/>
    </>
  )
}

export default About