import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

const Blogdetail = () => {
  return (
    <>
    <Navbar/>
    <a 
    href="https://api.whatsapp.com/send?phone=7081056230&text=Hello%20nice%20to%20meet%20you" 
    class="float" 
    target="_blank">
    <small className='me-2'></small>
    <i class="bi bi-whatsapp my-float"></i>
</a>
    <div class="container-fluid page-header py-5 mb-5">
        <div class="container py-5">
            <h1 class="display-3 text-white mb-3 animated slideInDown">Blogs</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                    <li class="breadcrumb-item"><a class="text-white" href="#">Blogs</a></li>
                </ol>
            </nav>
        </div>
    </div>


    <div className="container blog-detail-page">
        <div className="row">
            <div className="col-lg-12">
                <img src="images/new21.jpg" alt="" />

                <div className="blog-content">
                    <h4>The Power and Convenience of Fingerprint Access Control</h4>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                        standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,
                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                        Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions
                        of Lorem Ipsum. 
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                        standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,
                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                        Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions
                        of Lorem Ipsum.
                        </p>
                        <div className="blog-box">
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.”</p>
                        </div>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                        standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,
                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                        Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions
                        of Lorem Ipsum.
                        </p>
                </div>
            </div>
        </div>
    </div>
    <div class="wrapper">
    <div class="card">
      <div class="card-banner">
        <p class="category-tag popular">Security</p>
        <img class="banner-img" src='images/new15.jpg' alt=''/>
      </div>
      <div class="card-body">
        <p class="blog-hashtag">#Security #cctv</p>
        <h2 class="blog-title"> The Power and Convenience of Fingerprint Access Control</h2>

        <div class="card-profile">
          <img class="profile-img" src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpYJQKSoM7S75P_KMRtQHqAAIPh133CSxByw&usqp=CAU' alt=''/>
          <div class="card-profile-info">
            <h3 class="profile-name">Eleanor Pea</h3>
            <p class="profile-followers">5.2k followers</p>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-banner">
      <p class="category-tag popular">Security</p>
      <img class="banner-img" src='images/new11.jpg' alt=''/>
      </div>
      <div class="card-body">
      <p class="blog-hashtag">#Security #cctv</p>
        <h2 class="blog-title"> The Power and Convenience of Fingerprint Access Control</h2>

        <div class="card-profile">
          <img class="profile-img" src='https://1.bp.blogspot.com/-vhmWFWO2r8U/YLjr2A57toI/AAAAAAAACO4/0GBonlEZPmAiQW4uvkCTm5LvlJVd_-l_wCNcBGAsYHQ/s16000/team-1-2.jpg' alt=''/>
          <div class="card-profile-info">
            <h3 class="profile-name">Darrell Steward</h3>
            <p class="profile-followers">15.7k followers</p>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-banner">
      <p class="category-tag popular">Security</p>
        <img class="banner-img" src='images/new5.jpg' alt=''/>
      </div>
      <div class="card-body">
      <p class="blog-hashtag">#Security #cctv</p>
      <h2 class="blog-title"> The Power and Convenience of Fingerprint Access Control</h2>

        <div class="card-profile">
          <img class="profile-img" src='https://1.bp.blogspot.com/-8c7QTLoyajs/YLjr2V6KYRI/AAAAAAAACO8/ViVPQpLWVM0jGh3RZhh-Ha1-1r3Oj62wQCNcBGAsYHQ/s16000/team-1-3.jpg' alt=''/>
          <div class="card-profile-info">
            <h3 class="profile-name">Savannah Nguyen</h3>
            <p class="profile-followers">18K followers</p>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-banner">
      <p class="category-tag popular">Security</p>
      <img class="banner-img" src='images/new16.jpg' alt=''/>
      </div>
      <div class="card-body">
      <p class="blog-hashtag">#Security #cctv</p>
      <h2 class="blog-title"> The Power and Convenience of Fingerprint Access Control</h2>

        <div class="card-profile">
          <img class="profile-img" src='https://1.bp.blogspot.com/-8c7QTLoyajs/YLjr2V6KYRI/AAAAAAAACO8/ViVPQpLWVM0jGh3RZhh-Ha1-1r3Oj62wQCNcBGAsYHQ/s16000/team-1-3.jpg' alt=''/>
          <div class="card-profile-info">
            <h3 class="profile-name">Savannah Nguyen</h3>
            <p class="profile-followers">18K followers</p>
          </div>
        </div>
      </div>
    </div>
  </div>
    <Footer/>
    </>
  )
}

export default Blogdetail