import React from 'react'
import Navbar from './Navbar';
import Footer from './Footer';

const Contact = () => {
  return (
   <>
   <Navbar/>
   <a 
    href="https://api.whatsapp.com/send?phone=7081056230&text=Hello%20nice%20to%20meet%20you" 
    class="float" 
    target="_blank">
    <small className='me-2'></small>
    <i class="bi bi-whatsapp my-float"></i>
</a>
   <div class="container-fluid page-header py-5">
        <div class="container py-5">
            <h1 class="display-3 text-white mb-3 animated slideInDown">Contact</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                    <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                    <li class="breadcrumb-item text-white active" aria-current="page">Contact</li>
                </ol>
            </nav>
        </div>
    </div>

    <div class="container-fluid bg-light overflow-hidden px-lg-0">
        <div class="container contact px-lg-0">
            <div class="row g-0 mx-lg-0">
                <div class="col-lg-6 contact-text py-5 wow fadeIn" data-wow-delay="0.5s">
                    <div class="p-lg-5 ps-lg-0">
                        <div class="section-title text-start">
                            <h1 class="display-5 mb-4">Contact Us</h1>
                        </div>
                        <p class="mb-4">Don't leave your security to chance. Partner with Excelnet security and experience the peace of
                            mind that comes with knowing your assets, property, and personnel are in capable hands. Contact us today to
                            schedule a consultation and learn more about how we can tailor our security services to meet your needs.</p>
                        <form>
                            <div class="row g-3">
                                <div class="col-md-6">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" id="name" placeholder="Your Name"/>
                                        <label for="name">Your Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating">
                                        <input type="email" class="form-control" id="email" placeholder="Your Email"/>
                                        <label for="email">Your Email</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" id="subject" placeholder="Subject"/>
                                        <label for="subject">Subject</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-floating contact-float">
                                        <textarea class="form-control" placeholder="Leave a message here" id="message"></textarea>
                                        <label for="message">Message</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button class="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-6 pe-lg-0 about-height">
                    <div class="position-relative h-100">
                        <iframe class="position-absolute w-100 h-100 map-class"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3605.5438423748!2d82.97081108015539!3d25.35308361988225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398e2dcb4a7c91bd%3A0x6005f9c8f7c36639!2sBhojuveer%20Sabji%20Mandi!5e0!3m2!1sen!2sin!4v1731562914417!5m2!1sen!2sin"
                        frameborder="0" allowfullscreen="" aria-hidden="false"
                        tabindex="0"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
   <Footer/>
   
   </>
  )
}

export default Contact