import React from 'react'

const Test = () => {
    
  return (
<>

<div className="container pt-3">
  <div className="row">
    <div className="col-lg-3">
      <div className="product-card">
        <img src="images/bed.jpg" alt="" />
        <div className="bed-name">
          <h5>Moscow Divan Bed</h5>
        </div>
        <hr />
        <div className="bed-content">
        <div className="bed-price">
          <strike>€460.98</strike>
          <h4>€234.99</h4>
          <span>Save €234.99</span>
        </div>
        <div className="bed-color">
          <img src="images/home.png" alt="" />
        </div>
        </div>
      </div>
    </div>
    <div className="col-lg-3">
      <div className="product-card">
        <img src="images/bed.jpg" alt="" />
        <div className="bed-name">
          <h5>Moscow Divan Bed</h5>
        </div>
        <hr />
        <div className="bed-content">
        <div className="bed-price">
          <strike>€460.98</strike>
          <h4>€234.99</h4>
          <span>Save €234.99</span>
        </div>
        <div className="bed-color">
          <img src="images/home.png" alt="" />
        </div>
        </div>
      </div>
    </div>
    <div className="col-lg-3">
      <div className="product-card">
        <img src="images/bed.jpg" alt="" />
        <div className="bed-name">
          <h5>Moscow Divan Bed</h5>
        </div>
        <hr />
        <div className="bed-content">
        <div className="bed-price">
          <strike>€460.98</strike>
          <h4>€234.99</h4>
          <span>Save €234.99</span>
        </div>
        <div className="bed-color">
          <img src="images/home.png" alt="" />
        </div>
        </div>
      </div>
    </div>
    <div className="col-lg-3">
      <div className="product-card">
        <img src="images/bed.jpg" alt="" />
        <div className="bed-name">
          <h5>Moscow Divan Bed</h5>
        </div>
        <hr />
        <div className="bed-content">
        <div className="bed-price">
          <strike>€460.98</strike>
          <h4>€234.99</h4>
          <span>Save €234.99</span>
        </div>
        <div className="bed-color">
          <img src="images/home.png" alt="" />
        </div>
        </div>
      </div>
    </div>
  </div>
</div>

</>
  )
}

export default Test