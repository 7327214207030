import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
  <>
   <div class="container-fluid bg-dark text-secondary footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container py-5">
            <div class="row g-5">
                <div class="col-lg-3 col-md-6">
                    <h5 class="text-light mb-4">Address</h5>
                    {/* <p class="mb-2"><i class="fa fa-map-marker-alt me-2"></i>Bhojubeer sabji mandi sindhora road &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; varanasi 221002</p> */}
                    <p class="mb-2">
    <a 
        href="https://www.google.com/maps?q=Bhojubeer+sabji+mandi+sindhora+road+varanasi+221002" 
        target="_blank" 
        rel="noopener noreferrer"
        class="no-underline">
        <i class="fa fa-map-marker-alt me-2"></i>
        Bhojubeer sabji mandi sindhora road &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; varanasi 221002
    </a>
</p>

                    <p class="mb-2">
                    <a href="tel:+917081056230" class="no-underline">
                    <i class="fa fa-phone-alt me-2" > +91 7081056230</i>
                  </a></p>
                    
                    <p class="mb-2"><i class="fa fa-envelope me-2" ></i>For Sales-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Link to="mailto:Sales@excelnetsecurity.com"  class="no-underline">
                     Sales@excelnetsecurity.com
                  </Link>{" "}
                   </p>
                    <div class="d-flex pt-2">
                        <a class="btn btn-square btn-outline-secondary rounded-circle me-2" href=""><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-square btn-outline-secondary rounded-circle me-2" href="https://www.facebook.com/profile.php?id=100071895092991&mibextid=ZbWKwL"
                  target="_blank"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-square btn-outline-secondary rounded-circle me-2" href=""><i class="fab fa-youtube"></i></a>
                        <a class="btn btn-square btn-outline-secondary rounded-circle me-2" href=""><i class="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <h5 class="text-light mb-4">Services</h5>
                    <a class="btn btn-link" href="/contact">Business Security</a>
                    <a class="btn btn-link" href="/contact">Fire Detection</a>
                    <a class="btn btn-link" href="/contact">Alarm Systems</a>
                    <a class="btn btn-link" href="/contact">CCTV & Video</a>
                    <a class="btn btn-link" href="/contact">Smart Home</a>
                    <a class="btn btn-link" href="/contact">Bouncer & Gunner</a>
                    <a class="btn btn-link" href="/contact">Event</a>
                </div>
                <div class="col-lg-3 col-md-6">
                    <h5 class="text-light mb-4">Quick Links</h5>
                    <a class="btn btn-link" href="/about">About Us</a>
                    <a class="btn btn-link" href="/contact">Contact Us</a>
                    <a class="btn btn-link" href="/service">Our Services</a>
                    <a class="btn btn-link" href="">Terms & Condition</a>
                    <a class="btn btn-link" href="">Support</a>
                </div>
                <div class="col-lg-3 col-md-6">
                    <h5 class="text-light mb-4">Newsletter</h5>
                    <p>Sign up to get the latest news Notification</p>
                    <div class="position-relative w-100">
                        <input class="form-control bg-transparent border-secondary w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email"/>
                        <button type="button" class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </>
  )
}

export default Footer